<template>
  <div class="sections">
    <SectionItem
      :text="$t('sectionItem.section-bakery.text')"
      :title="$t('sectionItem.section-bakery.title')"
      image="inicio-familia-panadera.jpg"
      :link="{name: 'panaderia'}"
    ></SectionItem>
    <SectionItem
      :text="$t('sectionItem.section-oven.text')"
      :title="$t('sectionItem.section-oven.title')"
      image="inicio-el-horno.jpg"
      :link="{name: 'horno'}"
    ></SectionItem>
    <SectionItem
      :text="$t('sectionItem.section-land.text')"
      :title="$t('sectionItem.section-land.title')"
      image="inicio-nuestra-tierra.jpg"
      :link="{name: 'tierra'}"
    ></SectionItem>
  </div>
</template>

<script>
import SectionItem from '@/components/SectionItem.vue';

export default {
  name: 'Sections',
  components: {
    SectionItem,
  },
};
</script>
<style lang="scss">
    @import '../styles/utilities/variables.scss';
    @import '../styles/utilities/mixins.scss';
    .sections {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px;
    }
    @media (min-width: 768px){
         .sections{
             flex-direction: row;
        }
     }
    @media (min-width: 1200px){
         .sections{
             margin: 0px 100px;
        }
     }
</style>
