<template>
  <div class="news">
    <a href="https://tienda.panaderiajesus.com/actualidad/" class="news__info">
      <div class="news__title--container">
        <h4 class="news__title">{{$t('news.title')}}</h4>
        <img :src="`${path}/images/section-arrow.svg`" >
      </div>
      <p class="news__text">{{$t('news.text')}}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
};
</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.news {
  background-color: $main-color;
  background-image: url(/images/bar.png);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__info {
    display: block;
    cursor: pointer;

    .news {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        margin: 0px 15px 10px 0px;
        padding: 0px;
        text-align: start;

        &--container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 26px;
          color: $color-text-inverted;
          > img {
            transition: transform 250ms ease;
          }
        }

        img {
          height: 25px;
          filter: invert(0%)
                  sepia(0%)
                  saturate(7462%)
                  hue-rotate(0deg)
                  brightness(106%)
                  contrast(108%);
        }
      }

      &__text {
        text-align: start;
        font-size: 20px;
        font-weight: 100;
        color: $color-text-inverted;
        margin: 0px;
      }
    }
    &:hover {
      img {
        transform: translateX(10px);
      }
    }
  }
}
</style>
