<template>
  <header class="header">
    <router-link :to="{name: 'home', params: { locale: $i18n.locale }}">
      <div class="header__logo" id="return-home">
        <img class="header__logo--img" :src="`${path}/images/logo.svg`" :alt="$t('header.logo')">
      </div>
    </router-link>
    <nav>
      <div
        class="header__hamburger"
        :class="isMenuOpen ? 'header__hamburger--open' : 'header__hamburger--close'"
        @click.prevent="toggle">
        <span class="first-bar"></span>
        <span class="second-bar"></span>
        <span class="third-bar"></span>
      </div>
      <Menu></Menu>
      <ul class="header__menu--list">
        <li>
          <router-link :to="{name: 'panaderia', params: { locale: $i18n.locale }}">
            {{$t('header.bakery')}}
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'horno', params: { locale: $i18n.locale }}">
            {{$t('header.oven')}}
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'tierra', params: { locale: $i18n.locale }}">
            {{$t('header.land')}}
          </router-link>
        </li>
        <li>
          <a href="https://tienda.panaderiajesus.com/actualidad/">
            {{$t('header.news')}}
          </a>
        </li>
        <li>
          <a href="https://tienda.panaderiajesus.com/">
            {{$t('header.shop')}}
          </a>
        </li>
        <li>
          <a @click="scrollToContact">
            {{$t('header.contact')}}
          </a>
        </li>
        <li>
          <a href="https://tienda.panaderiajesus.com/carrito/">
            <img :src="`${path}/images/header-cart.svg`" :alt="$t('header.cart')">
          </a>
        </li>
        <li>
          <a href="https://tienda.panaderiajesus.com/mi-cuenta/">
            <img :src="`${path}/images/header-user.svg`" :alt="$t('header.user')">
          </a>
        </li>
        <li class="header__langs">
          <ul>
            <li v-for="(locale, i) in locales" :key="i">
              <a @click="selectLocale(locale)">
                <img :src="`${path}/images/langs/${locale.code}.svg`" :alt="locale.name">
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { locales } from '@/constants';
import Menu from '@/components/Menu.vue';

export default {
  name: 'Header',
  components: {
    Menu,
  },
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.isMenuOpen;
    },
    locales() {
      return locales;
    },
  },
  methods: {
    toggle() {
      this.$store.dispatch('toggle');
    },
    scrollToContact() {
      const container = document.getElementById('contact');
      if (container) {
        container.scrollIntoView({ block: 'center' });
      } else {
        this.$router.push({ name: 'home', hash: 'contact' });
      }
    },
    selectLocale(locale) {
      this.$i18n.locale = locale.code;
      const route = {
        name: this.$route.name,
        query: this.$route.query,
        params: { ...this.$route.params, locale: locale.code },
      };
      this.$router.replace(route, () => {
        this.$router.go(0);
      });
    },
  },
};

</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 60px;
  top: 0px;
  position: fixed;
  z-index: 99;
  background: #fbfbfb;

  &__menu--list {
    display: none;

    img {
      height: 25px;
    }

    li {
      padding: 10px;
      font-size: 16px;
      transition: opacity 200ms ease;
      &:hover {
        opacity: 0.6;
      }
      &.header__langs {
        padding: 0;
        &:hover {
          opacity: 1;
        }
        ul {
          display: flex;
        }
      }
    }

    a {
      cursor: pointer;
    }
  }

  &__logo {
    &--img {
      height: 30px;
    }
  }

  &__hamburger {
    transform: scale(0.7);
    &--close {
      span {
        display: block;
        width: 33px;
        height: 4px;
        border-radius: 10px;
        background-color: $color-text-default;
        margin-bottom: 7px;
        position: relative;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
          opacity 0.55s ease;
      }
    }

    &--open {
      span {
        display: block;
        width: 33px;
        height: 4px;
        border-radius: 10px;
        background-color: $color-text-default;
        margin-bottom: 7px;
        position: relative;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
          opacity 0.55s ease;
      }

      .first-bar {
        transform-origin: 0% 0%;
        transform: rotate(45deg)
      }

      .second-bar {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      .third-bar {
        transform-origin: 0% 100%;
        transform: rotate(-45deg)
      }
    }
  }
}

;

@media (min-width: 860px) {
  .header {
    height: 120px;
    padding: 20px 30px;

    &__logo {
      &--img {
        height: 50px;
      }
    }

    &__menu--list {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    &__hamburger {

      &--open,
      &--close {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  .header {
    padding-top: 80px;
    padding-bottom: 80px;

    &__logo {
      &--img {
        height: 70px;
      }
    }

    &__menu--list li {
      font-size: 20px;
    }
  }
}

</style>
