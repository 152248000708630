<template>
  <div class="clients">
    <div class="flickity" ref="carousel">
      <div class="carousel-cell" v-for="(c, i) in customers" :key="i">
        <div class="carousel-cell__img">
          <img :src="`${path}/images/${$t('clients.item'+ (i+1) + '.img')}`">
        </div>
        <div class="carousel-cell__info">
          <p class="carousel-cell__text">{{$t(`clients.item${i+1}.text`)}}</p>
          <div
            class="carousel-cell__title--container"
            @click="goTo($t('clients.item'+ (i+1) + '.url'))">
            <h5 class="carousel-cell__title">{{$t(`clients.item${i+1}.name`)}}</h5>
            <img :src="`${path}/images/section-arrow.svg`" class="carousel-cell__icon-img">
          </div>
          <p class="carousel-cell__text">{{$t(`clients.item${i+1}.work-name`)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity';

export default {
  name: 'Clients',
  data() {
    return {
      flick: null,
      timeout: null,
      currentItem: 0,
      path: process.env.VUE_APP_ABS_PATH,
      customers: new Array(8),
    };
  },
  mounted() {
    this.flick = new Flickity(this.$refs.carousel, {
      selectedAttraction: 0.2,
      friction: 0.8,
      contain: true,
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 5000,
    });
  },
  methods: {
    goTo(url) {
      // window.location.href = url;
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
};

</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.clients {
  padding-top: 40px;
  height: 420px;
  overflow: hidden;

  .carousel-cell {
    width: 150px;
    height: 200px;
    margin-right: 30px;
    border-radius: 5px;
    counter-increment: carousel-cell;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 0px;

    &__img {
      height: 150px;
      width: 150px;
      border-radius: 15px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }

    &__info {
      width: 150px;
      padding: 15px;
    }

    &__title {
      margin: 0px;
      font-size: 16px;
      font-weight: 600;
      ;
      color: $color-text-inverted;

      &--container {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        img {
          transition: transform 250ms ease;
        }
        &:hover img {
          transform: translateX(6px);
        }
      }
    }

    &__text {
      font-size: 14px;
      text-align: start;
      color: $color-text-inverted;
      margin: 0px 0px 18px;
      cursor: default;
    }

    &__icon-img {
      height: 15px;
      margin-left: 10px;
      filter: invert(0%) sepia(0%) saturate(7462%) hue-rotate(0deg) brightness(106%) contrast(108%);
    }
  }
}

@media (min-width: 768px) {
  .clients {
    height: 280px;

    .carousel-cell {
      width: 380px;
      height: 200px;
      display: flex;
      flex-direction: row;

      &__img {
        height: 160px;
        width: 160px;
      }

      &__info {
        width: 220px;
        padding: 15px;
      }
    }
  }
}

</style>
