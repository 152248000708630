<template>
  <div class="hero">
    <div class="hero__background" :style="{backgroundImage: `url(${path}/images/${image})`}">
      <div class="hero__text-container">
        <p class="hero__text">{{title}}</p>
        <div class="hero__btn-container" v-if="buttons">
          <button
            @click="goToAbout()"
            class="hero__btn">{{$t('hero.more')}}</button>
          <button
            @click="goToShop()"
            class="hero__btn hero__btn--inverted">{{$t('hero.shop')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    buttons: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToShop() {
      window.location.href = 'https://tienda.panaderiajesus.com/';
    },
    goToAbout() {
      this.$router.push({ name: 'panaderia' });
    },
  },
};
</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.hero {
  width: 100%;
  height: 100vh;
  color: white;
  font-weight: 700;
  text-align: center;

  &__background {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text {
    font-size: 25px;
    font-weight: inherit;
    max-width: 900px;
    line-height: normal;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);

    &-container {
      padding: 30px;
      max-width: 100%;
    }
  }

  &__btn {
    margin: 20px 30px;
    @include button;

    &:hover {
      @include button-hover;
    }

    &--inverted {
      @include button-hover;

      &:hover {
        @include button;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 768px) {
    .hero {
      &__text {
        font-size: 30px;

        &-container {
          padding: 60px;
        }
      }

      &__btn {
        font-size: 20px;

        &--inverted {
          font-size: 20px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .hero {
      &__text {
        font-size: 40px;

        &-container {
          padding: 30px;
        }
      }

      &__btn {
        font-size: 20px;
        min-width: 170px;

        &--inverted {
          font-size: 20px;
          min-width: 170px;
        }
      }
    }
  }
}

.page--home {
  .hero {
    color: black;
    font-weight: normal;
    &__text {
      text-shadow: none;
    }
  }
}

</style>
