<template>
  <div class="shop">
    <h1 class="shop__title">
      {{$t('shop.title-1')}} <em>{{$t('shop.title-2')}}</em> {{$t('shop.title-3')}}
    </h1>
    <p class="shop__text">{{$t('shop.text')}}</p>
    <div class="flickity" ref="carousel">
      <div class="carousel-cell">
        <div
          class="carousel-cell__img"
          :style="{backgroundImage: `url(${path}/images/shop1.png)`}"></div>
        <div
          class="carousel-cell__info"
          @click="goTo('http://tienda.panaderiajesus.com/categoria-producto/panes-secos/')">
          <div class="carousel-cell__title--container">
            <h2 class="carousel-cell__title">{{$t('shop.item1.title')}}</h2>
            <img
              :src="`${path}/images/section-arrow.svg`"
              class="carousel-cell__icon-img">
          </div>
          <p class="carousel-cell__text">{{$t('shop.item1.text')}}</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div
          class="carousel-cell__img"
          :style="{backgroundImage: `url(${path}/images/shop2.png)`}"></div>
        <div
          class="carousel-cell__info"
          @click="goTo('http://tienda.panaderiajesus.com/categoria-producto/panes-frescos/')">
          <div class="carousel-cell__title--container">
            <h2 class="carousel-cell__title">{{$t('shop.item2.title')}}</h2>
            <img
              :src="`${path}/images/section-arrow.svg`"
              class="carousel-cell__icon-img">
          </div>
          <p class="carousel-cell__text">{{$t('shop.item2.text')}}</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div
          class="carousel-cell__img"
          :style="{backgroundImage: `url(${path}/images/shop4.png)`}"></div>
        <div
          class="carousel-cell__info"
          @click="goTo('http://tienda.panaderiajesus.com/categoria-producto/panes-secos/crujientes/')">
          <div class="carousel-cell__title--container">
            <h2 class="carousel-cell__title">{{$t('shop.item3.title')}}</h2>
            <img
              :src="`${path}/images/section-arrow.svg`"
              class="carousel-cell__icon-img">
          </div>
          <p class="carousel-cell__text">{{$t('shop.item3.text')}}</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div
          class="carousel-cell__img"
          :style="{backgroundImage: `url(${path}/images/shop3.png)`}"></div>
        <div
          class="carousel-cell__info"
          @click="goTo('http://tienda.panaderiajesus.com/categoria-producto/dulces-caseros/')">
          <div class="carousel-cell__title--container">
            <h2 class="carousel-cell__title">{{$t('shop.item4.title')}}</h2>
            <img
              :src="`${path}/images/section-arrow.svg`"
              class="carousel-cell__icon-img">
          </div>
          <p class="carousel-cell__text">{{$t('shop.item4.text')}}</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div
          class="carousel-cell__img"
          :style="{backgroundImage: `url(${path}/images/shop5.png)`}"></div>
        <div
          class="carousel-cell__info"
          @click="goTo('http://tienda.panaderiajesus.com/categoria-producto/panes-secos/canas-de-pan-feo/')">
          <div class="carousel-cell__title--container">
            <h2 class="carousel-cell__title">{{$t('shop.item5.title')}}</h2>
            <img
              :src="`${path}/images/section-arrow.svg`"
              class="carousel-cell__icon-img">
          </div>
          <p class="carousel-cell__text">{{$t('shop.item5.text')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Flickity from 'flickity';

export default {
  name: 'Shop',
  data() {
    return {
      flick: null,
      timeout: null,
      currentItem: 0,
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
  mounted() {
    this.flick = new Flickity(this.$refs.carousel, {
      selectedAttraction: 0.2,
      friction: 0.8,
      contain: true,
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 4000,
    });
  },
  methods: {
    goTo(link) {
      window.location.href = link;
    },
  },
};

</script>
<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.shop {
  background-color: $color-background-diffent;
  background-image: url(/images/Espigas-Vector.svg);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 50px 0 100px;

  &__title {
    text-align: start;
    max-width: 800px;
    margin-bottom: 8px;
    font-size: 34px;
    margin-left: 30px;
  }

  &__text {
    text-align: start;
    font-size: 20px;
    font-weight: 100;
    margin: 0px 30px 50px;
  }

  .carousel-cell {
    width: 160px;
    height: 360px;
    margin-right: 30px;
    border-radius: 15px;
    overflow: hidden;
    counter-increment: carousel-cell;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $color-background-default;

    &__img {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &__info {
      cursor: pointer;
      width: 160px;
      background-color: $color-background-default;
      padding: 30px 30px 15px;
      transition: background 200ms ease, color 200ms ease;
    }

    &__title {
      margin: 0px;
      font-size: 20px;
      font-weight: 600;

      &--container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__text {
      font-size: 14px;
      text-align: start;
    }

    &__icon-img {
      margin-right: -14px;
      height: 15px;
      filter: invert(0%)
              sepia(90%)
              saturate(7462%)
              hue-rotate(58deg)
              brightness(-106%)
              contrast(108%);
    }

    &:hover {
      .carousel-cell {
        &__info {
          background-color: $main-color;
          color: $color-text-inverted;
        }

        &__icon-img {
          filter: invert(0%)
                  sepia(0%)
                  saturate(7462%)
                  hue-rotate(0deg)
                  brightness(106%)
                  contrast(108%);
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .shop {
    .carousel-cell {
      width: 400px;

      &__img {
        height: 200px;
      }

      &__info {
        width: 400px;
      }

      &__text {
        font-size: 16px;
        text-align: start;
      }
    }
  }
}

</style>
