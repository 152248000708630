<template>
  <div class="footer">
    <div class="footer__main">
      <nav>
        <ul class="footer__main--legal">
          <li>
            <a href="https://tienda.panaderiajesus.com/aviso-legal/">
              {{$t('footer.legal-warning')}}
            </a>
          </li>
          <li>
            <a href="https://tienda.panaderiajesus.com/condiciones-de-uso/">
              {{$t('footer.conditions-use')}}
            </a>
          </li>
          <li>
            <a href="https://tienda.panaderiajesus.com/politica-de-privacidad/">
              {{$t('footer.privacy-policy')}}
            </a>
          </li>
          <li>
            <a href="https://tienda.panaderiajesus.com/politica-de-cookies/">
              {{$t('footer.cookie-policy')}}
            </a>
          </li>
          <li>
            <a href="https://tienda.panaderiajesus.com/condiciones-de-compra/">
              {{$t('footer.buy-conditions')}}
            </a>
          </li>
          <li>
            <a href="https://tienda.panaderiajesus.com/preguntas-frecuentes/">
              {{$t('footer.questions')}}
            </a>
          </li>
          <li>
            <a @click="scrollToContact">
              {{$t('header.contact')}}
            </a>
          </li>
        </ul>
      </nav>
      <nav>
        <ul class="footer__main--pay">
          <li><img :src="`${path}/images/footer1.svg`" alt="Stripe" /></li>
          <li><img :src="`${path}/images/footer3.svg`" alt="Visa" /></li>
          <li><img :src="`${path}/images/footer4.svg`" alt="MasterCard" /></li>
          <li><img :src="`${path}/images/footer5.svg`" alt="American Express" /></li>
          <li><img :src="`${path}/images/footer6.svg`" alt="Discover" /></li>
        </ul>
      </nav>
    </div>
    <div class="footer__small">
      <small>{{$t('footer.small')}}</small>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
      year: new Date().getFullYear(),
    };
  },
  methods: {
    scrollToContact() {
      const container = document.getElementById('contact');
      if (container) {
        container.scrollIntoView({ block: 'center' });
      } else {
        this.$router.push({ name: 'home', hash: 'contact' });
      }
    },
  },
};

</script>
<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.footer {
  margin-top: -1px;
  color: $color-text-inverted;

  &__main {
    background-color: $color-backgroud-footer;
    padding: 30px 0px;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 30px;

      li {
        margin: 6px 12px;
      }

      a {
        font-size: 16px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        margin: 0px 10px;
      }

    }

    &--pay {
      img {
        margin: 0px 10px;
      }
    }
  }

  &__small {
    background-color: $color-backgroud-footer-small;
    padding: 20px 30px;
    font-size: 16px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .footer {
    &__main {
      ul {
        a {
          font-size: 18px;
        }
      }
    }
  }
}

</style>
