/* eslint-disable class-methods-use-this */

/**
 * ApiService class
 * Handles communication between app and API
 */
class ApiService {
  token = null;

  url(urlCode) {
    const base = 'https://tienda.panaderiajesus.com/wp-json/wp/v2/';

    switch (urlCode) {
      /* eslint-disable no-multi-spaces */
      case 'oferta':                      return `${base}pages/3941`; // Page oferta del mes

      default: break;
      /* eslint-enable no-multi-spaces */
    }
    return '/';
  }

  /**
   * Get headers for requests
   * - If the access token exists, return Auth Bearer,
   *   else send cookies with the request
   */
  getHeaders() {
    return this.token
      ? { headers: { Authorization: `Bearer ${this.token}` } }
      : { headers: {}, withCredentials: true };
  }

  clearToken() {
    this.token = null;
  }

  setToken(data) {
    this.token = data;
  }

  getToken() {
    return this.token;
  }
}

const API = new ApiService();

export default API;
