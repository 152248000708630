import Vue from 'vue';
import VueRouter from 'vue-router';
import { locales } from '@/constants';
import HomePage from '@/views/Home.vue';
import i18n from '@/i18n';
import HttpService from '@/services/http/HttpService';

Vue.use(VueRouter);

const localRegex = () => {
  let reg = '';
  locales.forEach((locale, i) => {
    reg = `${reg}${locale.code}${i !== locales.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
};

const getLocale = (locale = 'es') => locales.find((loc) => loc.code === locale);

const setLocale = (to, from, next) => {
  let locale = getLocale(to.params.locale);
  if (!to.params.locale && !!from.params.locale) {
    locale = getLocale(from.params.locale);
  }

  HttpService.get(`${process.env.VUE_APP_ABS_PATH}/translations/${locale.translations}`)
    .then((response) => {
      i18n.setLocaleMessage(locale.code, response.data || {});
    }).catch(() => {
      // TODO handle error
    }).finally(() => {
      i18n.locale = locale.code;
      to.params.locale = locale.code; // eslint-disable-line
      next();
    });
};

const routes = [
  {
    path: `/:locale${localRegex()}?`,
    name: 'home',
    component: HomePage,
  },
  {
    path: `/:locale${localRegex()}?/contact`,
    name: 'contact',
    component: HomePage,
  },
  {
    path: `/:locale${localRegex()}?/familia-panadera`,
    name: 'panaderia',
    component: () => import('@/views/Panaderia.vue'),
  },
  {
    path: `/:locale${localRegex()}?/el-horno`,
    name: 'horno',
    component: () => import('@/views/Horno.vue'),
  },
  {
    path: `/:locale${localRegex()}?/nuestra-tierra`,
    name: 'tierra',
    component: () => import('@/views/Tierra.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes: routes.map((route) => ({ ...route, beforeEnter: setLocale })),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
