<template>
  <div class="page page--home">
    <Hero
      :title="$t('home.hero')"
      :buttons="true"
      image="hero.jpg"
    ></Hero>
    <OfertaDelMes/>
    <Sections/>
    <Shop/>
    <BreadStick/>
    <News/>
    <Contact/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import OfertaDelMes from '@/components/OfertaDelMes.vue';
import Sections from '@/components/Sections.vue';
import Shop from '@/components/Shop.vue';
import BreadStick from '@/components/BreadStick.vue';
import News from '@/components/News.vue';
import Contact from '@/components/Contact.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    OfertaDelMes,
    Sections,
    Shop,
    BreadStick,
    News,
    Contact,
  },
  mounted() {
    const section = this.$router.currentRoute.hash.replace('#', '');
    if (section) {
      this.$nextTick(() => window.document.getElementById(section).scrollIntoView());
    }
  },
};
</script>
