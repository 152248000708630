import Vue from 'vue';
import Vuex from 'vuex';
import API from '@/services/api/ApiService';
import HttpService from '@/services/http/HttpService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMenuOpen: false,
  },
  mutations: {
    toggle(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
  },
  actions: {
    toggle(context, isMenuOpen) {
      return new Promise((resolve) => {
        this.commit('toggle', isMenuOpen);
        resolve();
      });
    },
    getOferta() {
      return new Promise((resolve, reject) => {
        HttpService.get(API.url('oferta'), API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
  },
  getters: {
    isMenuOpen(state) { return state.isMenuOpen; },
  },
});
