<template>
  <div class="menu__wrap">
    <div :class="isMenuOpen ? 'menu--black' : 'menu--close'" @click.prevent="toggle"></div>
    <div :class="isMenuOpen ? 'menu--open' : 'menu--close'">
      <ul class="menu__list">
        <li @click.prevent="toggle">
          <router-link :to="{name: 'panaderia', params: { locale: $i18n.locale }}">
            {{$t('header.bakery')}}
          </router-link>
        </li>
        <li @click.prevent="toggle">
          <router-link :to="{name: 'horno', params: { locale: $i18n.locale }}">
            {{$t('header.oven')}}
          </router-link>
        </li>
        <li @click.prevent="toggle">
          <router-link :to="{name: 'tierra', params: { locale: $i18n.locale }}">
            {{$t('header.land')}}
          </router-link>
        </li>
        <li>
          <a href="https://tienda.panaderiajesus.com/actualidad/">
            {{$t('header.news')}}
          </a>
        </li>
        <li>
          <a href="https://tienda.panaderiajesus.com/">
            {{$t('header.shop')}}
          </a>
        </li>
        <li @click.prevent="toggle">
          <a @click="scrollToContact">
            {{$t('header.contact')}}
          </a>
        </li>
        <li>
          <ul class="menu__list__icons">
            <li>
              <a href="https://tienda.panaderiajesus.com/carrito/">
                <img :src="`${path}/images/header-cart.svg`" :alt="$t('header.cart')">
              </a>
            </li>
            <li>
              <a href="https://tienda.panaderiajesus.com/mi-cuenta/">
                <img :src="`${path}/images/header-user.svg`" :alt="$t('header.user')">
              </a>
            </li>
          </ul>
        </li>
        <li class="menu__langs">
          <ul>
            <li v-for="(locale, i) in locales" :key="i">
              <a @click="selectLocale(locale)">
                <img :src="`${path}/images/langs/${locale.code}.svg`" :alt="locale.name">
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { locales } from '@/constants';

export default {
  name: 'Menu',
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.isMenuOpen;
    },
    locales() {
      return locales;
    },
  },
  methods: {
    toggle() {
      this.$store.dispatch('toggle');
    },
    scrollToContact() {
      const container = document.getElementById('contact');
      if (container) {
        container.scrollIntoView({ block: 'center' });
      } else {
        this.$router.push({ name: 'home', hash: 'contact' });
      }
    },
    selectLocale(locale) {
      this.$i18n.locale = locale.code;
      const route = {
        name: this.$route.name,
        query: this.$route.query,
        params: { ...this.$route.params, locale: locale.code },
      };
      this.$router.replace(route, () => {
        this.$router.go(0);
      });
    },
  },
};
</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
.menu{
  &--close{
    display: block;
    position: fixed;
    overflow: hidden;
    right: -100%;
    transition: 0.2s ease;
  }
  &--black{
    height: calc(100% - 58px);;
    width: 100vw;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: $color-backgroud-footer-small;
    opacity: 0.9;
    margin-top: 58px;
    transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.2s ease;
  }
  &--open{
    display:block;
    position:fixed;
    top: 0;
    z-index: 99;
    background-color: $color-background-default;
    width: 240px;
    height: calc(100% - 58px);
    margin: 58px 0 0 181px;
    padding: 20px 0px;
    transform: translate(-149%);
    border-top: 1px solid $color-background-diffent;
    overflow-y: auto;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    font-size: 16px;
  }
  &__list{
    li{
      padding: 12px 40px 12px 0px;
      text-align: end;
      cursor: pointer;
    }
    &__icons{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 200px;
      img {
        height: 25px;
      }
      & > li {
        padding:0px 0px 0px 30px;
      }
    }
  }
  &__langs {
    ul {
      display: flex;
      justify-content: flex-end;
    }
    li {
      padding: 0 0 0 15px;
    }
    img {
      width: 25px;
      height: auto;
    }
  }
}
@media (min-width: 768px){
  .menu--open, .menu--black{
    display:none;
  }
}
</style>
