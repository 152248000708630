<template>
  <div class="ofertames" v-if="postData.id">
    <div class="ofertames__wrap">
      <h2>{{postData.title.rendered}}</h2>
      <div
        class="ofertames__content"
        v-html="sanitize(postData.content.rendered)"
      ></div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  name: 'OfertaDelMes',
  data() {
    return {
      postData: {},
    };
  },
  mounted() {
    this.$store.dispatch('getOferta')
      .then((response) => {
        this.postData = response.data;
      });
  },
  methods: {
    sanitize(str) {
      return DOMPurify.sanitize(str);
    },
  },
};
</script>

<style lang="scss">
.ofertames {
  background: #a41b1a;
  color: white;
  padding: 20px;

  &__wrap {
    max-width: 600px;
    margin: 0 auto 30px;
  }

  h2 {
    text-align: center;
    font-size: 40px;
  }

  .has-text-align-center {
    text-align: center;
  }

  .wp-block-button {
    margin-top: 35px;
    text-align: center;
    a {
      margin: 0 10px;
      padding: 10px 30px;
      font-size: 18px;
      background-color: #FFFFFF;
      color: #000000;
      border: 0;
      border-radius: 10px;
      min-width: 160px;
      cursor: pointer;
      border: 0;
      &:hover {
        background: #eaeaea;
      }
    }
  }
}
</style>
