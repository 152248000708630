<template>
  <div class="contact__background">
    <Clients />
    <div class="contact" id="contact">
      <div class="contact__us">
        <h5 class="contact__us--title">{{$t('contact.title')}}</h5>
        <p class="contact__us--subtitle">{{$t('contact.subtitle')}}</p>
        <p class="contact__us--text">{{$t('contact.text')}}</p>
        <div class="contact__data--social">
          <a target="_blank" href="https://www.facebook.com/jesuspanaderos/">
            <img :src="`${path}/images/sm-facebook.svg`" alt="Facebook">
          </a>
          <a target="_blank" href="https://twitter.com/panaderia_jesus">
            <img :src="`${path}/images/sm-twitter.svg`" alt="Twitter">
          </a>
          <a target="_blank" href="https://www.instagram.com/panaderiajesus/">
            <img :src="`${path}/images/sm-instagram.svg`" alt="Instagram">
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UC8UsMTSBQZT_pAj_b36ZUrA">
            <img :src="`${path}/images/sm-youtube.svg`" alt="Youtube">
          </a>
        </div>
      </div>
      <div class="contact__data">
        <p class="contact__data--title">{{$t('contact.phone')}}</p>
        <p class="contact__data--text">{{$t('contact.phone-data')}}</p>
        <p class="contact__data--title">{{$t('contact.email')}}</p>
        <p class="contact__data--text">{{$t('contact.email-data')}}</p>
        <p class="contact__data--title">{{$t('contact.address')}}</p>
        <p class="contact__data--text">{{$t('contact.address-data')}}</p>
        <p class="contact__data--title">{{$t('contact.schedule')}}</p>
        <p class="contact__data--text">{{$t('contact.schedule-data')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Clients from '@/components/Clients.vue';

export default {
  name: 'clients',
  components: {
    Clients,
  },
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
};
</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.contact {
  display: flex;
  flex-direction: column;
  color: black;

  &__background {
    background-color: $main-color;
    background-image: url(/images/contact.jpg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
  }

  &__us {
    padding: 0px 30px 30px;

    &--title {
      text-align: start;
      max-width: 800px;
      padding: 0px;
      margin-bottom: 0px;
      font-size: 34px;
      font-weight: 300;
    }

    &--subtitle {
      font-size: 26px;
      font-weight: 600;
      text-align: start;
      margin: 0;
      margin-bottom: 25px;
    }

    &--text {
      text-align: start;
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 26px;
    }
  }

  &__data {
    margin: 0px 30px 30px;
    padding: 30px;
    background-color: rgba($color-background-default, 0.15);
    border-radius: 15px;

    &--title {
      text-align: start;
      font-size: 20px;
      font-weight: 600;
      margin: 0px 0px 5px;
    }

    &--text {
      text-align: start;
      font-size: 20px;
      font-weight: 100;
      margin: 0px 0px 25px;
      word-break: break-word;
    }

    &--social {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      img {
        height: 45px;
        margin: 0px 5px;
      }

      img:hover {
        border-radius: 100px;
        transition: 1s;
      }
    }
  }
}

@media (min-width: 768px) {
  .contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__us {
      width: 50%;
    }

    &__data {
      width: 50%;
    }
  }
}

@media (min-width: 1200px) {
  .contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 50px 60px;

    &__us {
      width: 40%;
    }

    &__data {
      width: 60%;
      max-width: 600px;
      padding: 50px;

      &--social {
        img {
          height: 50px;
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>
