<template>
  <section
    class="sectionitem"
    @click="goToLink()"
    :style="{backgroundImage: `url(${path}/images/${image})`}">
    <div class="sectionitem__info">
      <div class="sectionitem__text--container">
        <div class="sectionitem__text">{{text}}</div>
        <div class="sectionitem__title">{{title}}</div>
      </div>
      <div class="sectionitem__icon">
        <img
          :src="`${path}/images/section-arrow.svg`"
          class="sectionitem__icon-img">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionItem',
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    goToLink() {
      this.$router.push(this.link);
    },
  },
};
</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.sectionitem {
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  margin: 20px 0;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    .sectionitem__icon {
      transform: translate(0, 0);
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    color: $color-text-inverted;
    text-align: start;
    box-sizing: border-box;
  }

  &__icon {
    transform: translate(100px, 0);
    transition: transform 200ms ease-in-out;
  }

  &__title {
    font-size: 28px;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0,0,0,1);
  }

  &__text {
    font-size: 16px;
    text-shadow: 0 1px 1px rgba(0,0,0,1);
  }
}

@media screen and (min-width: 1080px) {
  .sectionitem {
    max-width: 30%;
    flex: 1 1 30%;
    height: 20vw;
  }
}

</style>
