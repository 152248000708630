<template>
  <div class="bread-stick">
    <img
      :src="`${path}/images/shop5.png`"
      :alt="$t('bread-stick__info--title')"
      class="bread-stick__img">
    <div class="bread-stick__info">
      <h3 class="bread-stick__info--title">{{$t('bread-stick.title')}}</h3>
      <h4 class="bread-stick__info--subtitle">{{$t('bread-stick.subtitle')}}</h4>
      <p class="bread-stick__info--text">{{$t('bread-stick.text1')}}</p>
      <p class="bread-stick__info--text">{{$t('bread-stick.text2')}}</p>
      <div class="bread-stick__info--link--container">
        <a href="https://tienda.panaderiajesus.com/producto/canas-de-pan-feo-135g/" class="bread-stick__info--link">
          <span>{{$t('bread-stick.link')}}</span>
          <img :src="`${path}/images/section-arrow.svg`">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadStick',
  data() {
    return {
      path: process.env.VUE_APP_ABS_PATH,
    };
  },
};
</script>

<style lang="scss">
@import '../styles/utilities/variables.scss';
@import '../styles/utilities/mixins.scss';

.bread-stick {
  display: block;
  padding: 50px 30px;
  width: 100%;

  &__img {
    background-image: url(/images/shop5.png);
    display: block;
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__info {
    &--title {
      text-align: start;
      max-width: 800px;
      padding: 30px 0px 0px;
      margin-bottom: 0px;
      font-size: 34px;
      font-weight: 300;
    }

    &--subtitle {
      font-size: 26px;
      font-weight: 600;
      text-align: start;
      color: $main-color;
      margin: 10px 0px 30px;
    }

    &--text {
      text-align: start;
      font-size: 16px;
    }

    &--link {
      font-weight: 900;
      &:hover {
        img {
          transform: translateX(10px);
        }
      }

      &--container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        img {
          transition: transform 250ms ease;
          height: 15px;
          margin-left: 10px;
          filter: invert(0%)
                  sepia(90%)
                  saturate(7462%)
                  hue-rotate(58deg)
                  brightness(-106%)
                  contrast(108%);
        }
      }
    }
  }

}

@media (min-width: 768px) {
  .bread-stick {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 30px 50px;

    &__img {
      width: 50%;
    }

    &__info {
      max-width: 500px;

      &--title {
        text-align: end;
      }

      &--subtitle {
        text-align: end;
      }

      &--text {
        text-align: end;
      }

      &--link {
        &--container {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
