module.exports = Object.freeze({
  locales: [
    {
      code: 'es',
      base: 'en',
      name: 'Español',
      translations: 'es.json',
    },
    {
      code: 'en',
      base: 'en',
      name: 'English',
      translations: 'en.json',
    },
  ],
});
